<template>
  <div class="container-fluid">
    <Header />
    <Breadcrumbs
      v-if="false"
      :title="currenttitle"
      :parent="parent"
      :current="current"
    />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="wrap-Theme Theme-page">
        <div class="mx-auto content-inner-container border-header">
          <div class="col-12 title-header">
            <img src="@/assets/images/theme/Brush.svg" class="mr-2" alt="" />
            <span>{{ lbl['consumer-theme-header'] }}</span>
          </div>
        </div>

        <div class="mx-auto content-inner-container">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-7 col-xl-8">
                <!-- Tab Bar Button -->
                <div v-if="!isBeeBenefit" class="row field-row">
                  <div
                    class="col col-md-3 field-column field-label col-form-label"
                  >
                    {{ lbl['hide-tab-bar'] }}
                  </div>

                  <div class="col-md-9 field-column align-top">
                    <span class="font-show">{{ lbl['theme-show-text'] }}</span
                    ><br />
                    <div class="mb-2">
                      <a-checkbox
                        :v-model="btnHome"
                        :checked="btnHome"
                        @change="
                          e => {
                            changeCheckBoxHome(e.target.checked)
                          }
                        "
                      >
                      </a-checkbox>
                      <span class="icon baseline">
                        <!-- <img
                          src="@/assets/images/theme/rewards.svg"
                          class="ml-2 mr-1"
                          alt
                        /> -->
                        <a-icon
                          type="home"
                          class="a-icons ml-2 mr-1"
                          theme="filled"
                        />
                      </span>
                      <span class="font-chk-box">
                        {{ lbl['hide-tab-bar-home'] }}
                      </span>
                    </div>
                    <div class="mb-2">
                      <a-checkbox
                        :v-model="btnProfile"
                        :checked="btnProfile"
                        @change="
                          e => {
                            changeCheckBoxProfile(e.target.checked)
                          }
                        "
                      >
                      </a-checkbox>
                      <span class="icon baseline">
                        <a-avatar
                          slot="avatar"
                          class="a-icons ml-2 mr-1"
                          :size="18"
                          style="background-color: #212529"
                          shape="square"
                          icon="user"
                        />
                      </span>
                      <span class="font-chk-box">
                        {{ lbl['hide-tab-bar-profile'] }}
                      </span>
                    </div>
                    <div class="mb-2">
                      <a-checkbox
                        :v-model="btnHistory"
                        :checked="btnHistory"
                        @change="
                          e => {
                            changeCheckBoxHistory(e.target.checked)
                          }
                        "
                      >
                      </a-checkbox>
                      <span class="icon baseline">
                        <a-icon
                          type="star"
                          class="a-icons ml-2 mr-1"
                          theme="filled"
                        />
                      </span>
                      <span class="font-chk-box">
                        {{ lbl['hide-tab-bar-history'] }}
                      </span>
                    </div>
                  </div>
                </div>

                <!-- Cover Image -->
                <div class="row field-row">
                  <div
                    class="
                      col col-md-3
                      field-column field-label
                      col-form-label
                      field-required
                    "
                  >
                    {{
                      isBeeBenefit
                        ? lbl['bee-benefit-consumer-theme-cover-image']
                        : lbl['consumer-theme-cover-image']
                    }}
                  </div>

                  <div class="col-md-9 field-column align-top">
                    <div>
                      <input
                        accept="image/jpg, image/jpeg, image/png"
                        class="form-control-file choose-file"
                        id="CoverImage"
                        name="CoverImageFile"
                        type="file"
                        @input="uploadImageCover($event)"
                      />
                      <label for="CoverImage">
                        <img
                          id="PreviewCoverImage"
                          class="upload-image-preview"
                          :src="imageCover"
                          alt=""
                        />
                      </label>
                    </div>
                    <div>
                      <span class="text-muted small">{{ imageSize }}</span>
                    </div>
                  </div>
                </div>

                <!-- Button -->
                <div class="row field-row">
                  <div
                    class="
                      col col-md-3
                      field-column field-label
                      col-form-label
                      field-required
                    "
                  >
                    {{
                      isBeeBenefit
                        ? lbl['bee-benefit-primary-color']
                        : lbl['consumer-theme-button']
                    }}
                  </div>

                  <div class="col-md-9 field-column">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <div class="field-label mb-2">
                          {{
                            isBeeBenefit
                              ? lbl['bee-benefit-primary-color-btn']
                              : lbl['consumer-theme-button-color']
                          }}
                        </div>
                        <div class="color-box-container mr-5">
                          <div class="button-color">
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_1,
                                color: white,
                              }"
                              @click="changeButtonColor(color_theme_1)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_2,
                                color: white,
                              }"
                              @click="changeButtonColor(color_theme_2)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_3,
                                color: white,
                              }"
                              @click="changeButtonColor(color_theme_3)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_4,
                                color: white,
                              }"
                              @click="changeButtonColor(color_theme_4)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_5,
                                color: white,
                              }"
                              @click="changeButtonColor(color_theme_5)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_6,
                                color: white,
                              }"
                              @click="changeButtonColor(color_theme_6)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_7,
                                color: white,
                              }"
                              @click="changeButtonColor(color_theme_7)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_8,
                                color: white,
                              }"
                              @click="changeButtonColor(color_theme_8)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_9,
                                color: white,
                              }"
                              @click="changeButtonColor(color_theme_9)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_10,
                                color: white,
                              }"
                              @click="changeButtonColor(color_theme_10)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_11,
                                color: white,
                              }"
                              @click="changeButtonColor(color_theme_11)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_12,
                                color: white,
                              }"
                              @click="changeButtonColor(color_theme_12)"
                            ></div>
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  :style="{
                                    'background-color': buttonColor,
                                    color: white,
                                  }"
                                  class="input-group-text select-color"
                                ></span>
                              </div>
                              <input
                                id="ButtonColor"
                                name="ButtonColor"
                                type="text"
                                class="form-control"
                                :value="buttonColor"
                                maxlength="7"
                                :class="{
                                  'is-invalid':
                                    isButtonColor || isHaxButtonColor,
                                }"
                                :aria-invalid="true"
                                @change="
                                  e => {
                                    changeInputButtonColor(e.target.value)
                                  }
                                "
                              />
                              <label
                                v-if="isButtonColor"
                                class="error invalid-feedback"
                                for="ButtonColor"
                                >{{
                                  lbl[
                                    'consumer-theme-button-color-required-message'
                                  ]
                                }}</label
                              >
                              <label
                                v-if="isHaxButtonColor"
                                class="error invalid-feedback"
                                for="ButtonColor"
                                >{{
                                  lbl[
                                    'consumer-theme-invalid-color-code-message'
                                  ]
                                }}</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <div class="field-label mb-2">
                          {{
                            isBeeBenefit
                              ? lbl['bee-benefit-primary-color-text']
                              : lbl['consumer-theme-text-button-color']
                          }}
                        </div>
                        <div class="color-box-container mr-5">
                          <div class="textbutton-color">
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_1,
                                color: white,
                              }"
                              @click="changeTextButtonColor(color_theme_1)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_2,
                                color: white,
                              }"
                              @click="changeTextButtonColor(color_theme_2)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_3,
                                color: white,
                              }"
                              @click="changeTextButtonColor(color_theme_3)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_4,
                                color: white,
                              }"
                              @click="changeTextButtonColor(color_theme_4)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_5,
                                color: white,
                              }"
                              @click="changeTextButtonColor(color_theme_5)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_6,
                                color: white,
                              }"
                              @click="changeTextButtonColor(color_theme_6)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_7,
                                color: white,
                              }"
                              @click="changeTextButtonColor(color_theme_7)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_8,
                                color: white,
                              }"
                              @click="changeTextButtonColor(color_theme_8)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_9,
                                color: white,
                              }"
                              @click="changeTextButtonColor(color_theme_9)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_10,
                                color: white,
                              }"
                              @click="changeTextButtonColor(color_theme_10)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_11,
                                color: white,
                              }"
                              @click="changeTextButtonColor(color_theme_11)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_12,
                                color: white,
                              }"
                              @click="changeTextButtonColor(color_theme_12)"
                            ></div>
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  :style="{
                                    'background-color': textButtonColor,
                                    color: white,
                                  }"
                                  class="input-group-text select-color"
                                ></span>
                              </div>
                              <input
                                id="TextButtonColor"
                                name="TextButtonColor"
                                type="text"
                                class="form-control"
                                :value="textButtonColor"
                                maxlength="7"
                                :class="{
                                  'is-invalid':
                                    isTextButtonColor || isHaxTextButtonColor,
                                }"
                                :aria-invalid="true"
                                @change="
                                  e => {
                                    changeInputTextButtonColor(e.target.value)
                                  }
                                "
                              />
                              <label
                                v-if="isTextButtonColor"
                                class="error invalid-feedback"
                                for="TextButtonColor"
                                >{{
                                  lbl[
                                    'consumer-theme-text-button-color-required-message'
                                  ]
                                }}</label
                              >
                              <label
                                v-if="isHaxTextButtonColor"
                                class="error invalid-feedback"
                                for="ButtonColor"
                                >{{
                                  lbl[
                                    'consumer-theme-invalid-color-code-message'
                                  ]
                                }}</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Redeem Point Label -->
                <div class="row field-row">
                  <div
                    class="
                      col col-md-3
                      field-column field-label
                      col-form-label
                      field-required
                    "
                  >
                    {{
                      isBeeBenefit
                        ? lbl['bee-benefit-secondary-color']
                        : lbl['lbl-redeem-point']
                    }}
                  </div>

                  <div class="col-md-9 field-column">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <div class="field-label mb-2">
                          {{
                            isBeeBenefit
                              ? lbl['bee-benefit-secondary-color-btn']
                              : lbl['consumer-theme-background-color']
                          }}
                        </div>
                        <div class="color-box-container mr-5">
                          <div class="button-color">
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_1,
                                color: white,
                              }"
                              @click="
                                changeRedeemBackgroundColor(color_theme_1)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_2,
                                color: white,
                              }"
                              @click="
                                changeRedeemBackgroundColor(color_theme_2)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_3,
                                color: white,
                              }"
                              @click="
                                changeRedeemBackgroundColor(color_theme_3)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_4,
                                color: white,
                              }"
                              @click="
                                changeRedeemBackgroundColor(color_theme_4)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_5,
                                color: white,
                              }"
                              @click="
                                changeRedeemBackgroundColor(color_theme_5)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_6,
                                color: white,
                              }"
                              @click="
                                changeRedeemBackgroundColor(color_theme_6)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_7,
                                color: white,
                              }"
                              @click="
                                changeRedeemBackgroundColor(color_theme_7)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_8,
                                color: white,
                              }"
                              @click="
                                changeRedeemBackgroundColor(color_theme_8)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_9,
                                color: white,
                              }"
                              @click="
                                changeRedeemBackgroundColor(color_theme_9)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_10,
                                color: white,
                              }"
                              @click="
                                changeRedeemBackgroundColor(color_theme_10)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_11,
                                color: white,
                              }"
                              @click="
                                changeRedeemBackgroundColor(color_theme_11)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_12,
                                color: white,
                              }"
                              @click="
                                changeRedeemBackgroundColor(color_theme_12)
                              "
                            ></div>
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  :style="{
                                    'background-color': redeemBackgroundColor,
                                    color: white,
                                  }"
                                  class="input-group-text select-color"
                                ></span>
                              </div>
                              <input
                                id="ButtonColor"
                                name="ButtonColor"
                                type="text"
                                class="form-control"
                                :value="redeemBackgroundColor"
                                maxlength="7"
                                :class="{
                                  'is-invalid':
                                    isRedeemBackgroundColor ||
                                    isHaxRedeemBackgroundColor,
                                }"
                                :aria-invalid="true"
                                @change="
                                  e => {
                                    changeInputRedeemBackgroundColor(
                                      e.target.value,
                                    )
                                  }
                                "
                              />
                              <label
                                v-if="isRedeemBackgroundColor"
                                class="error invalid-feedback"
                                for="ButtonColor"
                                >{{
                                  lbl[
                                    'consumer-theme-button-color-required-message'
                                  ]
                                }}</label
                              >
                              <label
                                v-if="isHaxRedeemBackgroundColor"
                                class="error invalid-feedback"
                                for="ButtonColor"
                                >{{
                                  lbl[
                                    'consumer-theme-invalid-color-code-message'
                                  ]
                                }}</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <div class="field-label mb-2">
                          {{
                            isBeeBenefit
                              ? lbl['bee-benefit-secondary-color-text']
                              : lbl['point-btn-text-color']
                          }}
                        </div>
                        <div class="color-box-container mr-5">
                          <div class="textbutton-color">
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_1,
                                color: white,
                              }"
                              @click="changeRedeemTextColor(color_theme_1)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_2,
                                color: white,
                              }"
                              @click="changeRedeemTextColor(color_theme_2)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_3,
                                color: white,
                              }"
                              @click="changeRedeemTextColor(color_theme_3)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_4,
                                color: white,
                              }"
                              @click="changeRedeemTextColor(color_theme_4)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_5,
                                color: white,
                              }"
                              @click="changeRedeemTextColor(color_theme_5)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_6,
                                color: white,
                              }"
                              @click="changeRedeemTextColor(color_theme_6)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_7,
                                color: white,
                              }"
                              @click="changeRedeemTextColor(color_theme_7)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_8,
                                color: white,
                              }"
                              @click="changeRedeemTextColor(color_theme_8)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_9,
                                color: white,
                              }"
                              @click="changeRedeemTextColor(color_theme_9)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_10,
                                color: white,
                              }"
                              @click="changeRedeemTextColor(color_theme_10)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_11,
                                color: white,
                              }"
                              @click="changeRedeemTextColor(color_theme_11)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_12,
                                color: white,
                              }"
                              @click="changeRedeemTextColor(color_theme_12)"
                            ></div>
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  :style="{
                                    'background-color': redeemTextColor,
                                    color: white,
                                  }"
                                  class="input-group-text select-color"
                                ></span>
                              </div>
                              <input
                                id="TextButtonColor"
                                name="TextButtonColor"
                                type="text"
                                class="form-control"
                                :value="redeemTextColor"
                                maxlength="7"
                                :class="{
                                  'is-invalid':
                                    isRedeemTextColor || isHaxRedeemTextColor,
                                }"
                                :aria-invalid="true"
                                @change="
                                  e => {
                                    changeInputRedeemTextColor(e.target.value)
                                  }
                                "
                              />
                              <label
                                v-if="isRedeemTextColor"
                                class="error invalid-feedback"
                                for="TextButtonColor"
                                >{{
                                  lbl[
                                    'consumer-theme-text-button-color-required-message'
                                  ]
                                }}</label
                              >
                              <label
                                v-if="isHaxRedeemTextColor"
                                class="error invalid-feedback"
                                for="ButtonColor"
                                >{{
                                  lbl[
                                    'consumer-theme-invalid-color-code-message'
                                  ]
                                }}</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Passcode -->
                <div class="row field-row">
                  <div
                    class="
                      col col-md-3
                      field-column field-label
                      col-form-label
                      field-required
                    "
                  >
                    {{
                      isBeeBenefit
                        ? lbl['bee-benefit-tertiary-color']
                        : lbl['pass-code']
                    }}
                  </div>

                  <div class="col-md-9 field-column">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <div class="field-label mb-2">
                          {{
                            isBeeBenefit
                              ? lbl['bee-benefit-tertiary-color-btn']
                              : lbl['consumer-theme-background-color']
                          }}
                        </div>
                        <div class="color-box-container mr-5">
                          <div class="button-color">
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_1,
                                color: white,
                              }"
                              @click="
                                changePasscodeBackgroundColor(color_theme_1)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_2,
                                color: white,
                              }"
                              @click="
                                changePasscodeBackgroundColor(color_theme_2)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_3,
                                color: white,
                              }"
                              @click="
                                changePasscodeBackgroundColor(color_theme_3)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_4,
                                color: white,
                              }"
                              @click="
                                changePasscodeBackgroundColor(color_theme_4)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_5,
                                color: white,
                              }"
                              @click="
                                changePasscodeBackgroundColor(color_theme_5)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_6,
                                color: white,
                              }"
                              @click="
                                changePasscodeBackgroundColor(color_theme_6)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_7,
                                color: white,
                              }"
                              @click="
                                changePasscodeBackgroundColor(color_theme_7)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_8,
                                color: white,
                              }"
                              @click="
                                changePasscodeBackgroundColor(color_theme_8)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_9,
                                color: white,
                              }"
                              @click="
                                changePasscodeBackgroundColor(color_theme_9)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_10,
                                color: white,
                              }"
                              @click="
                                changePasscodeBackgroundColor(color_theme_10)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_11,
                                color: white,
                              }"
                              @click="
                                changePasscodeBackgroundColor(color_theme_11)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_12,
                                color: white,
                              }"
                              @click="
                                changePasscodeBackgroundColor(color_theme_12)
                              "
                            ></div>
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  :style="{
                                    'background-color': passcodeBackgroundColor,
                                    color: white,
                                  }"
                                  class="input-group-text select-color"
                                ></span>
                              </div>
                              <input
                                id="ButtonColor"
                                name="ButtonColor"
                                type="text"
                                class="form-control"
                                :value="passcodeBackgroundColor"
                                maxlength="7"
                                :class="{
                                  'is-invalid':
                                    isPasscodeBackgroundColor ||
                                    isHaxPasscodeBackgroundColor,
                                }"
                                :aria-invalid="true"
                                @change="
                                  e => {
                                    changeInputPasscodeBackgroundColor(
                                      e.target.value,
                                    )
                                  }
                                "
                              />
                              <label
                                v-if="isPasscodeBackgroundColor"
                                class="error invalid-feedback"
                                for="ButtonColor"
                                >{{
                                  lbl[
                                    'consumer-theme-button-color-required-message'
                                  ]
                                }}</label
                              >
                              <label
                                v-if="isHaxPasscodeBackgroundColor"
                                class="error invalid-feedback"
                                for="ButtonColor"
                                >{{
                                  lbl[
                                    'consumer-theme-invalid-color-code-message'
                                  ]
                                }}</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <div class="field-label mb-2">
                          {{
                            isBeeBenefit
                              ? lbl['bee-benefit-tertiary-color-text']
                              : lbl['point-btn-text-color']
                          }}
                        </div>
                        <div class="color-box-container mr-5">
                          <div class="textbutton-color">
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_1,
                                color: white,
                              }"
                              @click="changePasscodeTextColor(color_theme_1)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_2,
                                color: white,
                              }"
                              @click="changePasscodeTextColor(color_theme_2)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_3,
                                color: white,
                              }"
                              @click="changePasscodeTextColor(color_theme_3)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_4,
                                color: white,
                              }"
                              @click="changePasscodeTextColor(color_theme_4)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_5,
                                color: white,
                              }"
                              @click="changePasscodeTextColor(color_theme_5)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_6,
                                color: white,
                              }"
                              @click="changePasscodeTextColor(color_theme_6)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_7,
                                color: white,
                              }"
                              @click="changePasscodeTextColor(color_theme_7)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_8,
                                color: white,
                              }"
                              @click="changePasscodeTextColor(color_theme_8)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_9,
                                color: white,
                              }"
                              @click="changePasscodeTextColor(color_theme_9)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_10,
                                color: white,
                              }"
                              @click="changePasscodeTextColor(color_theme_10)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_11,
                                color: white,
                              }"
                              @click="changePasscodeTextColor(color_theme_11)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_12,
                                color: white,
                              }"
                              @click="changePasscodeTextColor(color_theme_12)"
                            ></div>
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  :style="{
                                    'background-color': passcodeTextColor,
                                    color: white,
                                  }"
                                  class="input-group-text select-color"
                                ></span>
                              </div>
                              <input
                                id="TextButtonColor"
                                name="TextButtonColor"
                                type="text"
                                class="form-control"
                                :value="passcodeTextColor"
                                maxlength="7"
                                :class="{
                                  'is-invalid':
                                    isPasscodeTextColor ||
                                    isHaxPasscodeTextColor,
                                }"
                                :aria-invalid="true"
                                @change="
                                  e => {
                                    changeInputPasscodeTextColor(e.target.value)
                                  }
                                "
                              />
                              <label
                                v-if="isPasscodeTextColor"
                                class="error invalid-feedback"
                                for="TextButtonColor"
                                >{{
                                  lbl[
                                    'consumer-theme-text-button-color-required-message'
                                  ]
                                }}</label
                              >
                              <label
                                v-if="isHaxPasscodeTextColor"
                                class="error invalid-feedback"
                                for="ButtonColor"
                                >{{
                                  lbl[
                                    'consumer-theme-invalid-color-code-message'
                                  ]
                                }}</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Stamp -->
                <div v-if="!isBeeBenefit" class="row field-row">
                  <div
                    class="
                      col col-md-3
                      field-column field-label
                      col-form-label
                      field-required
                    "
                  >
                    {{ lbl['consumer-theme-stamp'] }}
                  </div>

                  <div class="col-md-9 field-column">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <div class="field-label mb-2">
                          {{ lbl['consumer-theme-background-color'] }}
                        </div>
                        <div class="color-box-container mr-5">
                          <div class="button-color">
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_1,
                                color: white,
                              }"
                              @click="changeStampBackgroundColor(color_theme_1)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_2,
                                color: white,
                              }"
                              @click="changeStampBackgroundColor(color_theme_2)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_3,
                                color: white,
                              }"
                              @click="changeStampBackgroundColor(color_theme_3)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_4,
                                color: white,
                              }"
                              @click="changeStampBackgroundColor(color_theme_4)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_5,
                                color: white,
                              }"
                              @click="changeStampBackgroundColor(color_theme_5)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_6,
                                color: white,
                              }"
                              @click="changeStampBackgroundColor(color_theme_6)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_7,
                                color: white,
                              }"
                              @click="changeStampBackgroundColor(color_theme_7)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_8,
                                color: white,
                              }"
                              @click="changeStampBackgroundColor(color_theme_8)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_9,
                                color: white,
                              }"
                              @click="changeStampBackgroundColor(color_theme_9)"
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_10,
                                color: white,
                              }"
                              @click="
                                changeStampBackgroundColor(color_theme_10)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_11,
                                color: white,
                              }"
                              @click="
                                changeStampBackgroundColor(color_theme_11)
                              "
                            ></div>
                            <div
                              class="color-box"
                              :style="{
                                'background-color': color_theme_12,
                                color: white,
                              }"
                              @click="
                                changeStampBackgroundColor(color_theme_12)
                              "
                            ></div>
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span
                                  :style="{
                                    'background-color': stampBackgroundColor,
                                    color: white,
                                  }"
                                  class="input-group-text select-color"
                                ></span>
                              </div>
                              <input
                                id="ButtonColor"
                                name="ButtonColor"
                                type="text"
                                class="form-control"
                                :value="stampBackgroundColor"
                                maxlength="7"
                                :class="{
                                  'is-invalid':
                                    isStampBackgroundColor ||
                                    isHaxStampBackgroundColor,
                                }"
                                :aria-invalid="true"
                                @change="
                                  e => {
                                    changeInputStampBackgroundColor(
                                      e.target.value,
                                    )
                                  }
                                "
                              />
                              <label
                                v-if="isStampBackgroundColor"
                                class="error invalid-feedback"
                                for="ButtonColor"
                                >{{
                                  lbl[
                                    'consumer-theme-button-color-required-message'
                                  ]
                                }}</label
                              >
                              <label
                                v-if="isHaxStampBackgroundColor"
                                class="error invalid-feedback"
                                for="ButtonColor"
                                >{{
                                  lbl[
                                    'consumer-theme-invalid-color-code-message'
                                  ]
                                }}</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <div class="field-label mb-2">
                          {{ lbl['consumer-theme-stamp-image'] }}
                        </div>
                        <div class="align-top mr-5">
                          <div>
                            <input
                              accept="image/jpg, image/jpeg, image/png"
                              class="form-control-file choose-file"
                              id="StampImage"
                              name="StampImageFile"
                              type="file"
                              @input="uploadImageStamp($event)"
                            />
                            <label for="StampImage">
                              <img
                                id="PreviewStampImage"
                                class="stamp-upload-image-preview"
                                :src="imageStamp"
                                alt=""
                              />
                            </label>
                          </div>
                          <div>
                            <span class="text-muted small">{{
                              imageStampSize
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!isBeeBenefit" class="col-lg-5 col-xl-4">
                <b-carousel
                  id="carousel-Theme"
                  :controls="true"
                  :indicators="true"
                  :interval="0"
                  img-width="1024"
                  style="text-shadow: 1px 1px 2px #333"
                >
                  <!-- Home -->
                  <b-carousel-slide img-blank>
                    <div class="wrapper-blog mx-auto">
                      <div class="text-center mt-2">
                        <strong class="wrapper-text-preview">
                          {{ lbl['consumer-theme-preview'] }}
                        </strong>
                        <div class="smartphone-container mt-3">
                          <div class="wrapper-blog smartphone mx-auto">
                            <div class="text-center">
                              <div class="wrapper-image-demo">
                                <div class="wrapper-header-demo">
                                  <img
                                    :src="imageCover"
                                    alt=""
                                    id="coverPreview"
                                    class="coverPreview bg-white"
                                  />
                                  <div class="wrapper-text-herder-center mt-4">
                                    <div
                                      class="wrapper-btn-color"
                                      :style="{
                                        'background-color': buttonColor,
                                        color: textButtonColor,
                                      }"
                                    >
                                      {{
                                        lbl[
                                          'consumer-theme-preview-point--get-point-button'
                                        ]
                                      }}
                                    </div>
                                  </div>
                                  <div class="wrapper-text-herder mt-4">
                                    <div>
                                      {{
                                        lbl[
                                          'consumer-theme-preview-point-current-point'
                                        ]
                                      }}
                                      :
                                      <strong>{{
                                        lbl[
                                          'consumer-theme-preview-point-current-point-sample'
                                        ]
                                      }}</strong>
                                    </div>
                                    <div>
                                      {{
                                        lbl[
                                          'consumer-theme-preview-point-detail'
                                        ]
                                      }}
                                    </div>
                                  </div>
                                  <hr class="line my-1" />
                                  <div class="wrapper-text-rewards">
                                    {{
                                      lbl[
                                        'consumer-theme-preview-point-campaign-header'
                                      ]
                                    }}
                                  </div>
                                  <div class="row">
                                    <div class="col">
                                      <img
                                        src="~@/assets/images/theme/preview-campaign-01.jpg"
                                        alt=""
                                        class="
                                          shadow-lg
                                          bg-white
                                          rounded
                                          wrapper-list-image
                                        "
                                      />
                                    </div>
                                    <div class="col wrapper-text-detail">
                                      <div>
                                        <strong>{{
                                          lbl[
                                            'consumer-theme-preview-point-campaign1-name'
                                          ]
                                        }}</strong>
                                      </div>
                                      <div
                                        class="redeem-point-box"
                                        :style="{
                                          'background-color':
                                            redeemBackgroundColor,
                                          color: redeemTextColor,
                                        }"
                                      >
                                        <span>{{
                                          lbl[
                                            'consumer-theme-preview-point-campaign1-point-use'
                                          ]
                                        }}</span>
                                      </div>
                                    </div>
                                    <div class="col m-auto">
                                      <img
                                        src="~@/assets/images/theme/next.png"
                                        alt=""
                                        class="w-25"
                                      />
                                    </div>
                                  </div>
                                  <hr class="line" />
                                  <div class="row">
                                    <div class="col">
                                      <img
                                        src="~@/assets/images/theme/preview-campaign-02.jpg"
                                        alt=""
                                        class="
                                          shadow-lg
                                          bg-white
                                          rounded
                                          wrapper-list-image
                                        "
                                      />
                                    </div>
                                    <div class="col wrapper-text-detail">
                                      <div>
                                        <strong>{{
                                          lbl[
                                            'consumer-theme-preview-point-campaign2-name'
                                          ]
                                        }}</strong>
                                      </div>
                                      <div
                                        class="redeem-point-box"
                                        :style="{
                                          'background-color':
                                            redeemBackgroundColor,
                                          color: redeemTextColor,
                                        }"
                                      >
                                        <span>{{
                                          lbl[
                                            'consumer-theme-preview-point-campaign2-point-use'
                                          ]
                                        }}</span>
                                      </div>
                                    </div>
                                    <div class="col m-auto">
                                      <img
                                        src="~@/assets/images/theme/next.png"
                                        alt=""
                                        class="w-25"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr class="line" />
                              <div class="container mb-2 menu-container">
                                <div class="row">
                                  <div class="col">
                                    <hr />
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col text-center red">
                                    <a-icon
                                      type="home"
                                      class="a-icons ml-2 mr-1"
                                      theme="filled"
                                    />
                                  </div>
                                  <div class="col text-center">
                                    <a-avatar
                                      slot="avatar"
                                      class="a-icons ml-2 mr-1"
                                      :size="18"
                                      style="background-color: #212529"
                                      shape="square"
                                      icon="user"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-carousel-slide>

                  <!-- Stamp -->
                  <b-carousel-slide img-blank>
                    <div class="wrapper-blog mx-auto">
                      <div class="text-center mt-2">
                        <strong class="wrapper-text-preview">
                          {{ lbl['consumer-theme-preview'] }}
                        </strong>
                        <div class="smartphone-container mt-3">
                          <div class="wrapper-blog smartphone mx-auto">
                            <div class="text-center">
                              <div class="wrapper-image-demo">
                                <div class="wrapper-header-demo">
                                  <img
                                    :src="imageCover"
                                    alt=""
                                    id="coverPreview"
                                    class="coverPreview bg-white"
                                  />
                                  <div
                                    class="wrapper-stamp shadow-lg"
                                    :style="{
                                      'background-color': stampBackgroundColor,
                                    }"
                                  >
                                    <div
                                      class="
                                        container
                                        wrapper-stamp-center
                                        mx-0
                                        px-0
                                      "
                                    >
                                      <div class="pt-2 pb-2">
                                        <div
                                          class="
                                            row
                                            wrapper-stamp-board
                                            pt-2
                                            pb-2
                                            justify-content-center
                                            align-items-center
                                          "
                                        >
                                          <div
                                            class="col stamp-item"
                                            :style="[
                                              {
                                                'background-image':
                                                  'url(' + imageStamp + ')',
                                              },
                                            ]"
                                          ></div>
                                          <div
                                            class="col stamp-item"
                                            :style="[
                                              {
                                                'background-image':
                                                  'url(' + imageStamp + ')',
                                              },
                                            ]"
                                          ></div>
                                          <div
                                            class="col stamp-item"
                                            :style="[
                                              {
                                                'background-image':
                                                  'url(' + imageStamp + ')',
                                              },
                                            ]"
                                          ></div>
                                          <div
                                            class="col stamp-item"
                                            :style="[
                                              {
                                                'background-image':
                                                  'url(' + imageStamp + ')',
                                              },
                                            ]"
                                          ></div>
                                          <div
                                            class="col stamp-item"
                                            :style="[
                                              {
                                                'background-image':
                                                  'url(' + imageStamp + ')',
                                              },
                                            ]"
                                          ></div>
                                        </div>
                                        <div
                                          class="
                                            row
                                            wrapper-stamp-board
                                            pt-2
                                            pb-4
                                            justify-content-center
                                            align-items-center
                                          "
                                        >
                                          <div
                                            class="col stamp-item"
                                            :style="[
                                              {
                                                'background-image':
                                                  'url(' + imageStamp + ')',
                                              },
                                            ]"
                                          ></div>
                                          <div
                                            class="col stamp-item"
                                            :style="[
                                              {
                                                'background-image':
                                                  'url(' + imageStamp + ')',
                                              },
                                            ]"
                                          ></div>
                                          <div
                                            class="col stamp-item"
                                            :style="[
                                              {
                                                'background-image':
                                                  'url(' + imageStamp + ')',
                                              },
                                            ]"
                                          ></div>
                                          <div
                                            class="col stamp-item"
                                            :style="[
                                              {
                                                'background-image':
                                                  'url(' + imageStamp + ')',
                                              },
                                            ]"
                                          ></div>
                                          <div
                                            class="col stamp-item"
                                            :style="[
                                              {
                                                'background-image':
                                                  'url(' + imageStamp + ')',
                                              },
                                            ]"
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="wrapper-text-herder-center mt-4">
                                    <div
                                      class="wrapper-btn-color"
                                      :style="{
                                        'background-color': buttonColor,
                                        color: textButtonColor,
                                      }"
                                    >
                                      {{
                                        lbl[
                                          'consumer-theme-preview-point--get-point-button'
                                        ]
                                      }}
                                    </div>
                                  </div>
                                  <hr class="line my-1" />
                                  <div class="wrapper-text-rewards">
                                    {{
                                      lbl[
                                        'consumer-theme-preview-point-campaign-header'
                                      ]
                                    }}
                                  </div>
                                  <div class="row">
                                    <div class="col">
                                      <img
                                        src="~@/assets/images/theme/preview-campaign-01.jpg"
                                        alt=""
                                        class="
                                          shadow-lg
                                          bg-white
                                          rounded
                                          wrapper-list-image
                                        "
                                      />
                                    </div>
                                    <div class="col wrapper-text-detail">
                                      <div>
                                        <strong>{{
                                          lbl[
                                            'consumer-theme-preview-point-campaign1-name'
                                          ]
                                        }}</strong>
                                      </div>
                                      <div
                                        class="redeem-point-box"
                                        :style="{
                                          'background-color':
                                            redeemBackgroundColor,
                                          color: redeemTextColor,
                                        }"
                                      >
                                        <span>{{
                                          lbl[
                                            'consumer-theme-preview-point-campaign1-point-use'
                                          ]
                                        }}</span>
                                      </div>
                                    </div>
                                    <div class="col m-auto">
                                      <img
                                        src="~@/assets/images/theme/next.png"
                                        alt=""
                                        class="w-25"
                                      />
                                    </div>
                                  </div>
                                  <hr class="line" />
                                  <div class="row">
                                    <div class="col">
                                      <img
                                        src="~@/assets/images/theme/preview-campaign-02.jpg"
                                        alt=""
                                        class="
                                          shadow-lg
                                          bg-white
                                          rounded
                                          wrapper-list-image
                                        "
                                      />
                                    </div>
                                    <div class="col wrapper-text-detail">
                                      <div>
                                        <strong>{{
                                          lbl[
                                            'consumer-theme-preview-point-campaign2-name'
                                          ]
                                        }}</strong>
                                      </div>
                                      <div
                                        class="redeem-point-box"
                                        :style="{
                                          'background-color':
                                            redeemBackgroundColor,
                                          color: redeemTextColor,
                                        }"
                                      >
                                        <span>{{
                                          lbl[
                                            'consumer-theme-preview-point-campaign2-point-use'
                                          ]
                                        }}</span>
                                      </div>
                                    </div>
                                    <div class="col m-auto">
                                      <img
                                        src="~@/assets/images/theme/next.png"
                                        alt=""
                                        class="w-25"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr class="line" />
                              <div class="container mb-2 menu-container">
                                <div class="row">
                                  <div class="col">
                                    <hr />
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col text-center red">
                                    <a-icon
                                      type="home"
                                      class="a-icons ml-2 mr-1"
                                      theme="filled"
                                    />
                                  </div>
                                  <div class="col text-center">
                                    <a-avatar
                                      slot="avatar"
                                      class="a-icons ml-2 mr-1"
                                      :size="18"
                                      style="background-color: #212529"
                                      shape="square"
                                      icon="user"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-carousel-slide>

                  <!-- Redeem -->
                  <b-carousel-slide img-blank>
                    <div class="wrapper-blog mx-auto">
                      <div class="text-center mt-2">
                        <strong class="wrapper-text-preview">
                          {{ lbl['consumer-theme-preview'] }}
                        </strong>
                        <div class="smartphone-container mt-3">
                          <div class="wrapper-blog smartphone mx-auto">
                            <div class="text-center">
                              <div class="wrapper-image-demo">
                                <div class="wrapper-header-demo">
                                  <img
                                    :src="imageCover"
                                    alt=""
                                    id="coverPreview"
                                    class="coverPreview bg-white"
                                  />

                                  <div class="wrapper-campaign-detail border">
                                    <div class="wrapper-text-header-redeem">
                                      {{
                                        lbl[
                                          'consumer-theme-preview-campaign-header'
                                        ]
                                      }}
                                    </div>
                                    <hr class="line" />
                                    <div class="wrapper-detail-desc">
                                      {{
                                        lbl[
                                          'consumer-theme-preview-campaign-detail'
                                        ]
                                      }}
                                    </div>
                                    <div class="transcriptGradient"></div>
                                    <div
                                      class="wrapper-text-herder-redeem mt-4"
                                    >
                                      <div
                                        class="wrapper-btn-color"
                                        :style="{
                                          'background-color': buttonColor,
                                          color: textButtonColor,
                                        }"
                                      >
                                        {{
                                          lbl[
                                            'consumer-theme-preview-campaign-redeem-button'
                                          ]
                                        }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="container mb-2 menu-container">
                                <div class="row">
                                  <div class="col">
                                    <hr />
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col text-center red">
                                    <a-icon
                                      type="home"
                                      class="a-icons ml-2 mr-1"
                                      theme="filled"
                                    />
                                  </div>
                                  <div class="col text-center">
                                    <a-avatar
                                      slot="avatar"
                                      class="a-icons ml-2 mr-1"
                                      :size="18"
                                      style="background-color: #212529"
                                      shape="square"
                                      icon="user"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-carousel-slide>

                  <!-- QR code -->
                  <b-carousel-slide img-blank>
                    <div class="wrapper-blog mx-auto">
                      <div class="text-center mt-2">
                        <strong class="wrapper-text-preview">
                          {{ lbl['consumer-theme-preview'] }}
                        </strong>
                        <div class="smartphone-container mt-3">
                          <div class="wrapper-blog smartphone mx-auto">
                            <div class="text-center">
                              <div class="wrapper-image-demo">
                                <div class="wrapper-header-demo">
                                  <img
                                    :src="imageCover"
                                    alt=""
                                    id="coverPreview"
                                    class="coverPreview bg-white"
                                  />

                                  <div class="wrapper-campaign-redeem border">
                                    <div
                                      class="
                                        wrapper-text-header-redeem
                                        d-inline-flex
                                      "
                                    >
                                      <div class="camp-img-preview-box"></div>
                                      Reward Name
                                    </div>
                                    <hr class="line" />
                                    <div class="wrapper-detail-desc px-0">
                                      <span class="small"
                                        >Please show the code below to the
                                        store.</span
                                      ><br />
                                      <div class="d-inline-flex small mb-3">
                                        <div
                                          class="
                                            active-tab-button-preview
                                            px-2
                                            mr-1
                                          "
                                        >
                                          QR Code
                                        </div>
                                        <div
                                          class="
                                            unactive-tab-button-preview
                                            px-2
                                            mr-1
                                          "
                                        >
                                          Barcode
                                        </div>
                                        <div
                                          class="
                                            unactive-tab-button-preview
                                            px-2
                                          "
                                        >
                                          Code
                                        </div>
                                      </div>
                                      <div class="text-center">
                                        <img
                                          class="w-50 pl-2"
                                          alt=""
                                          src="~@/assets/images/theme/preview_qr_code.png"
                                        />
                                        <br /><span class="small"
                                          >xxxxxxxx</span
                                        >
                                      </div>
                                    </div>
                                    <div class="transcriptGradient"></div>
                                    <div
                                      class="wrapper-text-herder-redeem mt-4"
                                    >
                                      <div
                                        class="wrapper-btn-passcode-color"
                                        :style="{
                                          'background-color':
                                            passcodeBackgroundColor,
                                          color: passcodeTextColor,
                                          'border-color': passcodeTextColor,
                                        }"
                                      >
                                        Passcode
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="container mb-2 menu-container">
                              <div class="row">
                                <div class="col">
                                  <hr />
                                </div>
                              </div>
                              <div class="row">
                                <div class="col text-center red">
                                  <a-icon
                                    type="home"
                                    class="a-icons"
                                    theme="filled"
                                  />
                                </div>
                                <div class="col text-center">
                                  <a-avatar
                                    slot="avatar"
                                    class="a-icons ml-2 mr-1"
                                    :size="18"
                                    style="background-color: #212529"
                                    shape="square"
                                    icon="user"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-carousel-slide>
                </b-carousel>
              </div>
              <div v-if="isBeeBenefit" class="col-lg-5 col-xl-4">
                <div class="mt-3 smartphone-banefit-container">
                  <img
                    src="@/assets/images/theme/preview.gif"
                    alt="test_gif"
                    class="preview-bee"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-7 col-xl-8">
                <div class="row float-right">
                  <b-button
                    variant="warning"
                    class="btn-submit mt-5"
                    @click="handleSubmit"
                  >
                    {{ lbl['consumer-theme-save-button'] }}
                  </b-button>
                </div>
              </div>
              <div class="col-lg-5 col-xl-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
import fileReader from '@/helper/fileReader'
import Header from '@/components/Layout/Header'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import Breadcrumbs from '@/module/Settings/3rdParty/components/Breadcrumbs.vue'
import AlertModal from '@/components/Modal/Component/alertmodal2'

export default {
  name: 'Theme',
  mixins: [Mixin],
  components: {
    Header,
    Breadcrumbs,
    AlertModal,
  },
  data() {
    return {
      currenttitle: 'Settings',
      current: 'Theme',
      parent: {},
      btnHome: true,
      btnProfile: true,
      btnHistory: true,
      imageSize: '',
      imageCover: '',
      imageStamp: '',
      white: '#ffffff',
      color_theme_1: '#ffffff',
      color_theme_2: '#f27b99',
      color_theme_3: '#efcec5',
      color_theme_4: '#d1af94',
      color_theme_5: '#97d5e0',
      color_theme_6: '#88b14b',
      color_theme_7: '#f6d258',
      color_theme_8: '#ef562d',
      color_theme_9: '#5587a2',
      color_theme_10: '#0c4c8a',
      color_theme_11: '#7d4c24',
      color_theme_12: '#000000',
      buttonColor: '#f95d07',
      isButtonColor: false,
      isHaxButtonColor: false,
      textButtonColor: '#ffffff',
      isTextButtonColor: false,
      isHaxTextButtonColor: false,
      redeemBackgroundColor: '#ffffff',
      isRedeemBackgroundColor: false,
      isHaxRedeemBackgroundColor: false,
      redeemTextColor: '#000000',
      isRedeemTextColor: false,
      isHaxRedeemTextColor: false,
      passcodeBackgroundColor: '#ffffff',
      isPasscodeBackgroundColor: false,
      isHaxPasscodeBackgroundColor: false,
      passcodeTextColor: '#000000',
      isPasscodeTextColor: false,
      isHaxPasscodeTextColor: false,
      stampBackgroundColor: '#8389a4',
      isStampBackgroundColor: false,
      isHaxStampBackgroundColor: false,
      imageStampSize: '',
      params: {},
      isSuccess: false,
      alertModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        isOpenModal: false,
      },
      isBeeBenefit: false,
    }
  },
  watch: {
    lbl(newVal, oldVal) {
      if (newVal != oldVal) {
        this.setLocale()
      }
    },
  },
  created() {
    this.handleFooter(true)
    Account.bzbsAnalyticTracking(
      'setting_theme_page',
      'setting_theme',
      'view_theme',
      'on view',
    )
    this.init()
  },
  methods: {
    init() {
      this.imageStamp =
        Vue.bzbsConfig.bzbsBlobUrl + '/linepartnership/default/stamp-star.png'
      this.imageCover =
        Vue.bzbsConfig.bzbsBlobUrl + '/linepartnership/default/bg-default.jpg'
      this.getCRMPlusProfile()
      var crmPackage = Account.getCachePackage()
      if (crmPackage && crmPackage.PackageId) {
        this.isBeeBenefit = crmPackage.PackageId.includes('benefit')
      }
    },
    setLocale() {
      this.currenttitle = this.lbl['main-menu-setting']
      this.current = this.lbl['main-menu-theme']
      this.parent = null
      this.imageSize = this.lbl[
        'consumer-theme-cover-image-recommended-message'
      ].replace('{0}', Vue.bzbsConfig.businessRule.max.photoSize)
      this.imageStampSize = this.lbl[
        'consumer-theme-stamp-image-recommended-message'
      ].replace('{0}', Vue.bzbsConfig.businessRule.max.photoSize)
    },
    alertModalAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          this.alertModalSetting.isOpenModal = false
        }, 3000)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
          }, 3000)
      }
    },
    getCRMPlusProfile() {
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.genProfile(res.data)
            resolve(res.data)
          })
          .catch(error => {
            console.log('getCRMPlusProfile error', error)
            resolve(error)
          })
      })
    },
    genProfile(data) {
      console.log('getCRMPlusProfile : ', data)
      this.buttonColor = data.ButtonColor ? data.ButtonColor : '#f95d07'
      this.textButtonColor = data.ButtonTextColor
        ? data.ButtonTextColor
        : '#ffffff'
      this.redeemBackgroundColor = data.PointButtonBackgroundColor
        ? data.PointButtonBackgroundColor
        : '#ffffff'
      this.redeemTextColor = data.PointButtonTextColor
        ? data.PointButtonTextColor
        : '#000000'
      this.passcodeBackgroundColor = data.PasscodeButtonColor
        ? data.PasscodeButtonColor
        : '#ffffff'
      this.passcodeTextColor = data.PasscodeTextColor
        ? data.PasscodeTextColor
        : '#000000'
      this.stampBackgroundColor = data.StampBackgroundColor
        ? data.StampBackgroundColor
        : '#8389a4'
      this.imageStamp = data.StampUrl
        ? data.StampUrl
        : Vue.bzbsConfig.bzbsBlobUrl + '/linepartnership/default/stamp-star.png'
      this.imageCover = data.CoverUrl
        ? data.CoverUrl
        : Vue.bzbsConfig.bzbsBlobUrl + '/linepartnership/default/bg-default.jpg'
      this.btnHome = !data.HideHomeTab
      this.btnProfile = !data.HideProfileTab
      this.btnHistory = !data.HideHistoryTab
      this.params.agencyId = data.SponsorId

      if (this.imageStamp) {
        fileReader.getBaseImage(this.imageStamp).then(data => {
          this.params.imageStamp = fileReader.blobToFile(data, 'imageStamp')
        })
      }
      if (this.imageCover) {
        fileReader.getBaseImage(this.imageCover).then(data => {
          this.params.imageCover = fileReader.blobToFile(data, 'imageCover')
        })
      }
    },
    changeCheckBoxHome(e) {
      this.btnHome = e
    },
    changeCheckBoxProfile(e) {
      this.btnProfile = e
    },
    changeCheckBoxHistory(e) {
      this.btnHistory = e
    },
    uploadImageCover(event) {
      var image = event.target.files[0]
      var afterDot = image.name.substr(0, image.name.indexOf('.'))
      var nonSpacial = afterDot.replace(/[^a-zA-Z0-9]/g, '')
      var newFileName = nonSpacial.replace(/\s/g, '_')
      var resultFile = new File([image], newFileName, { type: image.type })
      this.imageCover = URL.createObjectURL(resultFile)
      console.log('this.imageStamp ::', this.imageCover)
      this.params.imageCover = resultFile
    },

    // Button
    changeButtonColor(color) {
      this.buttonColor = color
    },
    changeInputButtonColor(color) {
      var reg = /^#([0-9a-f]{3}){1,2}$/i
      this.buttonColor = color
      if (this.buttonColor.length == 0) {
        this.isButtonColor = true
        this.isHaxButtonColor = false
      } else {
        this.isButtonColor = false
        if (reg.test(this.buttonColor)) {
          this.isHaxButtonColor = false
        } else {
          this.isHaxButtonColor = true
        }
      }
    },
    changeTextButtonColor(color) {
      this.textButtonColor = color
    },
    changeInputTextButtonColor(color) {
      var reg = /^#([0-9a-f]{3}){1,2}$/i
      this.textButtonColor = color
      if (this.textButtonColor.length == 0) {
        this.isTextButtonColor = true
        this.isHaxTextButtonColor = false
      } else {
        this.isTextButtonColor = false
        if (reg.test(this.textButtonColor)) {
          this.isHaxTextButtonColor = false
        } else {
          this.isHaxTextButtonColor = true
        }
      }
    },

    // Redeem Point Label
    changeRedeemBackgroundColor(color) {
      this.redeemBackgroundColor = color
    },
    changeInputRedeemBackgroundColor(color) {
      var reg = /^#([0-9a-f]{3}){1,2}$/i
      this.redeemBackgroundColor = color
      if (this.redeemBackgroundColor.length == 0) {
        this.isRedeemBackgroundColor = true
        this.isHaxRedeemBackgroundColor = false
      } else {
        this.isRedeemBackgroundColor = false
        if (reg.test(this.redeemBackgroundColor)) {
          this.isHaxRedeemBackgroundColor = false
        } else {
          this.isHaxRedeemBackgroundColor = true
        }
      }
    },
    changeRedeemTextColor(color) {
      this.redeemTextColor = color
    },
    changeInputRedeemTextColor(color) {
      var reg = /^#([0-9a-f]{3}){1,2}$/i
      this.redeemTextColor = color
      if (this.redeemTextColor.length == 0) {
        this.isRedeemTextColor = true
        this.isHaxRedeemTextColor = false
      } else {
        this.isRedeemTextColor = false
        if (reg.test(this.redeemTextColor)) {
          this.isHaxRedeemTextColor = false
        } else {
          this.isHaxRedeemTextColor = true
        }
      }
    },

    // Passcode
    changePasscodeBackgroundColor(color) {
      this.passcodeBackgroundColor = color
    },
    changeInputPasscodeBackgroundColor(color) {
      var reg = /^#([0-9a-f]{3}){1,2}$/i
      this.passcodeBackgroundColor = color
      if (this.passcodeBackgroundColor.length == 0) {
        this.isPasscodeBackgroundColor = true
        this.isHaxPasscodeBackgroundColor = false
      } else {
        this.isPasscodeBackgroundColor = false
        if (reg.test(this.passcodeBackgroundColor)) {
          this.isHaxPasscodeBackgroundColor = false
        } else {
          this.isHaxPasscodeBackgroundColor = true
        }
      }
    },
    changePasscodeTextColor(color) {
      this.passcodeTextColor = color
    },
    changeInputPasscodeTextColor(color) {
      var reg = /^#([0-9a-f]{3}){1,2}$/i
      this.passcodeTextColor = color
      if (this.passcodeTextColor.length == 0) {
        this.isPasscodeTextColor = true
        this.isHaxPasscodeTextColor = false
      } else {
        this.isPasscodeTextColor = false
        if (reg.test(this.passcodeTextColor)) {
          this.isHaxPasscodeTextColor = false
        } else {
          this.isHaxPasscodeTextColor = true
        }
      }
    },

    // Stamp
    changeStampBackgroundColor(color) {
      this.stampBackgroundColor = color
    },
    changeInputStampBackgroundColor(color) {
      var reg = /^#([0-9a-f]{3}){1,2}$/i
      this.stampBackgroundColor = color
      if (this.stampBackgroundColor.length == 0) {
        this.isStampBackgroundColor = true
        this.isHaxStampBackgroundColor = false
      } else {
        this.isStampBackgroundColor = false
        if (reg.test(this.stampBackgroundColor)) {
          this.isHaxStampBackgroundColor = false
        } else {
          this.isHaxStampBackgroundColor = true
        }
      }
    },
    uploadImageStamp(event) {
      var image = event.target.files[0]
      var afterDot = image.name.substr(0, image.name.indexOf('.'))
      var nonSpacial = afterDot.replace(/[^a-zA-Z0-9]/g, '')
      var newFileName = nonSpacial.replace(/\s/g, '_')
      var resultFile = new File([image], newFileName, { type: image.type })
      this.imageStamp = URL.createObjectURL(resultFile)
      console.log('this.imageStamp ::', this.imageStamp)
      this.params.imageStamp = resultFile
    },

    handleSubmit() {
      Account.bzbsAnalyticTracking(
        'setting_theme_page',
        'setting_theme',
        'click_theme_update',
        'on click',
      )
      this.isSuccess = false
      console.log('handleSubmit')
      this.params.layoutId = '1'
      this.params.buttonColor = this.buttonColor
      this.params.buttonTextColor = this.textButtonColor
      this.params.stampBackgroundColor = this.stampBackgroundColor
      this.params.hideHomeTab = !this.btnHome
      this.params.hideProfileTab = !this.btnProfile
      this.params.hideHistoryTab = !this.btnHistory
      this.params.pointButtonBackgroundColor = this.redeemBackgroundColor
      this.params.pointButtonTextColor = this.redeemTextColor
      this.params.passcodeButtonColor = this.passcodeBackgroundColor
      this.params.passcodeTextColor = this.passcodeTextColor

      return new Promise(resolve => {
        BzbsProfile.updateThemes(this.params)
          .then(res => {
            console.log('updateThemes : ', res)
            this.isSuccess = true
            this.alertModalAction(
              this.lbl['alert-box-success-header'],
              this.lbl['consumer-theme-save-success-message'],
              'success',
              true,
            )
            window.scrollTo(0, 0)
            resolve(res.data)
          })
          .catch(error => {
            console.log('updateThemes error', error)
            this.alertModalAction(
              this.lbl['alert-box-error-header'],
              this.lbl['alert-box-error-header'],
              'error',
              true,
            )
            resolve(error)
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import '../styles/theme_main.scss';
.red {
  color: red;
}
</style>
